// PurchasingFolder.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const PurchasingFolder = (props) => {
  const iconsData = [
    {
      title: "Orders",
      color: "green",
      altColor: "olive",
      linkto: "/purchasing/orders",
    },
    {
      title: "Backorders",
      color: "red",
      altColor: "maroon",
      linkto: "/purchasing/backorders",
    },
  ];

  return <CasaAppFolder title={props.title} iconsData={iconsData} />;
};

export default PurchasingFolder;
