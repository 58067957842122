import { createContext, useContext, useState } from "react";

const InventoryContext = createContext();

export const useInventoryContext = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error(
      "useInventoryContext must be used within an InventoryProvider"
    );
  }
  return context;
};

export const InventoryProvider = ({ children }) => {
  const [inventoryState, setInventoryState] = useState({
    searchType: "",
    partNumber: "",
    activeStep: 0,
    direction: "left",

    filters: [],
    filterArrays: [],

    // For 'Assembly' app
    assemblyDepartmentIndex: 0, // used to keep track of department switching between screens
    assemblyType: "",
    assemblyCategory: "",
    assemblyCategoryOptions: [],
    assemblyGroup: "",
    assemblyGroupOptions: [],
    assemblySearch: "",
    assembly: null,
    stageOptions: [],

    // For 'Assembly' app, but for adding new parts
    newItemType: "",
    newItemTypeOptions: [],
    newItemCategory: "",
    newItemCategoryOptions: [],
    newItemGroup: "",
    newItemGroupOptions: [],
    newItemSearch: "",
    newItem: {},
    showNewItem: false,

    // For 'Review' app
    reviewDepartmentIndex: 0, // used to keep track of department switching between screens
    type: "",
    typeOptions: [],
    category: "",
    categoryOptions: [],
    group: "",
    groupOptions: [],
    reviewSearchKeyword: "",
    showFilterSearch: true,

    // For 'Create' app
    createType: "",
    createTypeOptions: [],
    createCategory: "",
    createCategoryOptions: [],
    createGroup: "",
    createGroupOptions: [],
    createDescription: "",
    createPartNum: "",
    createUnit: "",
    createCost: "",
    createQty: "",
    createNotes: "",
    createItem: null, // used for changing screens on 'Create' button press
  });

  const updateInventory = (newState) => {
    setInventoryState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <InventoryContext.Provider value={{ inventoryState, updateInventory }}>
      {children}
    </InventoryContext.Provider>
  );
};
