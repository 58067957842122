// InventoryFolder.js
import React from "react";
import CasaAppFolder from "../../Resuable/CasaAppFolder/CasaAppFolder";

const InventoryFolder = (props) => {
  const iconsData = [
    // {
    //   title: "Filter",
    //   color: "#004506",
    //   altColor: "#00bf10",
    //   linkto: "/inventory/filter",
    // },
    {
      title: "Assembly",
      color: "#08005C",
      altColor: "#0F2F78",
      linkto: "/inventory/assembly",
    },
    {
      title: "Counts",
      color: "red",
      altColor: "orange",
      linkto: "/inventory/count",
    },
    {
      title: "Review",
      color: "#004506",
      altColor: "#00bf10",
      linkto: "/inventory/review",
    },
    // {
    //   title: "Search",
    //   color: "#B60080",
    //   altColor: "#934747",
    //   linkto: "/inventory/search",
    // },
    {
      title: "Create",
      color: "#B60080",
      altColor: "#934747",
      linkto: "/inventory/create",
    },
  ];

  return <CasaAppFolder title={props.title} iconsData={iconsData} />;
};

export default InventoryFolder;
